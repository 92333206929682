import React from "react"
import Racepage from "../components/racepage"

const Giro2020 = () => {
  const startlist = [
    {
      firstname: " Edoardo",
      lastname: " ZARDINI",
      number: " 218",
      team: " Vini Zabù - KTM",
    },
    {
      firstname: " Luca",
      lastname: " WACKERMANN",
      number: " 217",
      team: " Vini Zabù - KTM",
    },
    {
      firstname: " Etienne",
      lastname: " VAN EMPEL",
      number: " 216",
      team: " Vini Zabù - KTM",
    },
    {
      firstname: " Matteo",
      lastname: " SPREAFICO",
      number: " 215",
      team: " Vini Zabù - KTM",
    },
    {
      firstname: " Lorenzo",
      lastname: " ROTA",
      number: " 214",
      team: " Vini Zabù - KTM",
    },
    {
      firstname: " Marco",
      lastname: " FRAPPORTI",
      number: " 213",
      team: " Vini Zabù - KTM",
    },
    {
      firstname: " Simone",
      lastname: " BEVILACQUA",
      number: " 212",
      team: " Vini Zabù - KTM",
    },
    {
      firstname: " Giovanni",
      lastname: " VISCONTI",
      number: " 211",
      team: " Vini Zabù - KTM",
    },
    {
      firstname: " Maximiliano",
      lastname: " RICHEZE",
      number: " 208",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Juan Sebastián",
      lastname: " MOLANO",
      number: " 207",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Brandon",
      lastname: " MCNULTY",
      number: " 206",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Fernando",
      lastname: " GAVIRIA",
      number: " 205",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Joe",
      lastname: " DOMBROWSKI",
      number: " 204",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Valerio",
      lastname: " CONTI",
      number: " 203",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Mikkel",
      lastname: " BJERG",
      number: " 202",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Diego",
      lastname: " ULISSI",
      number: " 201",
      team: " UAE-Team Emirates",
    },
    {
      firstname: " Pieter",
      lastname: " WEENING",
      number: " 198",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Antonio",
      lastname: " NIBALI",
      number: " 197",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Jacopo",
      lastname: " MOSCA",
      number: " 196",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Nicola",
      lastname: " CONCI",
      number: " 195",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Giulio",
      lastname: " CICCONE",
      number: " 194",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Gianluca",
      lastname: " BRAMBILLA",
      number: " 193",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Julien",
      lastname: " BERNARD",
      number: " 192",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Vincenzo",
      lastname: " NIBALI",
      number: " 191",
      team: " Trek - Segafredo",
    },
    {
      firstname: " Martijn",
      lastname: " TUSVELD",
      number: " 188",
      team: " Team Sunweb",
    },
    {
      firstname: " Sam",
      lastname: " OOMEN",
      number: " 187",
      team: " Team Sunweb",
    },
    {
      firstname: " Michael",
      lastname: " MATTHEWS",
      number: " 186",
      team: " Team Sunweb",
    },
    {
      firstname: " Jai",
      lastname: " HINDLEY",
      number: " 185",
      team: " Team Sunweb",
    },
    {
      firstname: " Chris",
      lastname: " HAMILTON",
      number: " 184",
      team: " Team Sunweb",
    },
    {
      firstname: " Chad",
      lastname: " HAGA",
      number: " 183",
      team: " Team Sunweb",
    },
    {
      firstname: " Nico",
      lastname: " DENZ",
      number: " 182",
      team: " Team Sunweb",
    },
    {
      firstname: " Wilco",
      lastname: " KELDERMAN",
      number: " 181",
      team: " Team Sunweb",
    },
    {
      firstname: " Jos",
      lastname: " VAN EMDEN",
      number: " 178",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Antwan",
      lastname: " TOLHOEK",
      number: " 177",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Christoph",
      lastname: " PFINGSTEN",
      number: " 176",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Tony",
      lastname: " MARTIN",
      number: " 175",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Chris",
      lastname: " HARPER",
      number: " 174",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Tobias",
      lastname: " FOSS",
      number: " 173",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Koen",
      lastname: " BOUWMAN",
      number: " 172",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Steven",
      lastname: " KRUIJSWIJK",
      number: " 171",
      team: " Team Jumbo-Visma",
    },
    {
      firstname: " Ben",
      lastname: " SWIFT",
      number: " 168",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Salvatore",
      lastname: " PUCCIO",
      number: " 167",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Jhonatan",
      lastname: " NARVÁEZ",
      number: " 166",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Tao",
      lastname: " GEOGHEGAN HART",
      number: " 165",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Filippo",
      lastname: " GANNA",
      number: " 164",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Rohan",
      lastname: " DENNIS",
      number: " 163",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Jonathan",
      lastname: " CASTROVIEJO",
      number: " 162",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Geraint",
      lastname: " THOMAS",
      number: " 161",
      team: " INEOS Grenadiers",
    },
    {
      firstname: " Danilo",
      lastname: " WYSS",
      number: " 158",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Dylan",
      lastname: " SUNDERLAND",
      number: " 157",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Matteo",
      lastname: " SOBRERO",
      number: " 156",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Domenico",
      lastname: " POZZOVIVO",
      number: " 155",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Ben",
      lastname: " O'CONNOR",
      number: " 154",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Amanuel",
      lastname: " GHEBREIGZABHIER",
      number: " 153",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Victor",
      lastname: " CAMPENAERTS",
      number: " 152",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Louis",
      lastname: " MEINTJES",
      number: " 151",
      team: " NTT Pro Cycling",
    },
    {
      firstname: " Davide",
      lastname: " VILLELLA",
      number: " 148",
      team: " Movistar Team",
    },
    {
      firstname: " Albert",
      lastname: " TORRES",
      number: " 147",
      team: " Movistar Team",
    },
    {
      firstname: " Eduardo",
      lastname: " SEPÚLVEDA",
      number: " 146",
      team: " Movistar Team",
    },
    {
      firstname: " Sergio",
      lastname: " SAMITIER",
      number: " 145",
      team: " Movistar Team",
    },
    {
      firstname: " Einer Augusto",
      lastname: " RUBIO",
      number: " 144",
      team: " Movistar Team",
    },
    {
      firstname: " Antonio",
      lastname: " PEDRERO",
      number: " 143",
      team: " Movistar Team",
    },
    {
      firstname: " Dario",
      lastname: " CATALDO",
      number: " 142",
      team: " Movistar Team",
    },
    {
      firstname: " Héctor",
      lastname: " CARRETERO",
      number: " 141",
      team: " Movistar Team",
    },
    {
      firstname: " Cameron",
      lastname: " MEYER",
      number: " 138",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Damien",
      lastname: " HOWSON",
      number: " 137",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Michael",
      lastname: " HEPBURN",
      number: " 136",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Lucas",
      lastname: " HAMILTON",
      number: " 135",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Jack",
      lastname: " HAIG",
      number: " 134",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Brent",
      lastname: " BOOKWALTER",
      number: " 133",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Edoardo",
      lastname: " AFFINI",
      number: " 132",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Simon",
      lastname: " YATES",
      number: " 131",
      team: " Mitchelton-Scott",
    },
    {
      firstname: " Harm",
      lastname: " VANHOUCKE",
      number: " 128",
      team: " Lotto Soudal",
    },
    {
      firstname: " Stefano",
      lastname: " OLDANI",
      number: " 127",
      team: " Lotto Soudal",
    },
    {
      firstname: " Matthew",
      lastname: " HOLMES",
      number: " 126",
      team: " Lotto Soudal",
    },
    {
      firstname: " Adam",
      lastname: " HANSEN",
      number: " 125",
      team: " Lotto Soudal",
    },
    {
      firstname: " Carl Fredrik",
      lastname: " HAGEN",
      number: " 124",
      team: " Lotto Soudal",
    },
    {
      firstname: " Jonathan",
      lastname: " DIBBEN",
      number: " 123",
      team: " Lotto Soudal",
    },
    {
      firstname: " Thomas",
      lastname: " DE GENDT",
      number: " 122",
      team: " Lotto Soudal",
    },
    {
      firstname: " Sander",
      lastname: " ARMÉE",
      number: " 121",
      team: " Lotto Soudal",
    },
    {
      firstname: " Rick",
      lastname: " ZABEL",
      number: " 118",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Guy",
      lastname: " SAGIV",
      number: " 117",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Daniel",
      lastname: " NAVARRO",
      number: " 116",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Alex",
      lastname: " DOWSETT",
      number: " 115",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Davide",
      lastname: " CIMOLAI",
      number: " 114",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Alexander",
      lastname: " CATAFORD",
      number: " 113",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Matthias",
      lastname: " BRÄNDLE",
      number: " 112",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Rudy",
      lastname: " BARBIER",
      number: " 111",
      team: " Israel Start-Up Nation",
    },
    {
      firstname: " Benjamin",
      lastname: " THOMAS",
      number: " 109",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Ramon",
      lastname: " SINKELDAM",
      number: " 107",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Miles",
      lastname: " SCOTSON",
      number: " 106",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Ignatas",
      lastname: " KONOVALOVAS",
      number: " 105",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Simon",
      lastname: " GUGLIELMI",
      number: " 104",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Jacopo",
      lastname: " GUARNIERI",
      number: " 103",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Kilian",
      lastname: " FRANKINY",
      number: " 102",
      team: " Groupama - FDJ",
    },
    {
      firstname: " Arnaud",
      lastname: " DÉMARE",
      number: " 101",
      team: " Groupama - FDJ",
    },
    {
      firstname: " James",
      lastname: " WHELAN",
      number: " 98",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Lachlan",
      lastname: " MORTON",
      number: " 97",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Tanel",
      lastname: " KANGERT",
      number: " 96",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Ruben",
      lastname: " GUERREIRO",
      number: " 95",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Lawson",
      lastname: " CRADDOCK",
      number: " 94",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Simon",
      lastname: " CLARKE",
      number: " 93",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Jonathan Klever",
      lastname: " CAICEDO",
      number: " 92",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Sean",
      lastname: " BENNETT",
      number: " 91",
      team: " EF Pro Cycling",
    },
    {
      firstname: " Pieter",
      lastname: " SERRY",
      number: " 88",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Fausto",
      lastname: " MASNADA",
      number: " 87",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " James",
      lastname: " KNOX",
      number: " 86",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Iljo",
      lastname: " KEISSE",
      number: " 85",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Mikkel Frølich",
      lastname: " HONORÉ",
      number: " 84",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Álvaro José",
      lastname: " HODEG",
      number: " 83",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Davide",
      lastname: " BALLERINI",
      number: " 82",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " João",
      lastname: " ALMEIDA",
      number: " 81",
      team: " Deceuninck - Quick Step",
    },
    {
      firstname: " Stéphane",
      lastname: " ROSSETTO",
      number: " 78",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Marco",
      lastname: " MATHIS",
      number: " 77",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Mathias",
      lastname: " LE TURNIER",
      number: " 76",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Jesper",
      lastname: " HANSEN",
      number: " 75",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Nathan",
      lastname: " HAAS",
      number: " 74",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Nicolas",
      lastname: " EDET",
      number: " 73",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Simone",
      lastname: " CONSONNI",
      number: " 72",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Elia",
      lastname: " VIVIANI",
      number: " 71",
      team: " Cofidis, Solutions Crédits",
    },
    {
      firstname: " Attila",
      lastname: " VALTER",
      number: " 68",
      team: " CCC Team",
    },
    {
      firstname: " Joey",
      lastname: " ROSSKOPF",
      number: " 67",
      team: " CCC Team",
    },
    {
      firstname: " Kamil",
      lastname: " MAŁECKI",
      number: " 66",
      team: " CCC Team",
    },
    {
      firstname: " Pavel",
      lastname: " KOCHETKOV",
      number: " 65",
      team: " CCC Team",
    },
    {
      firstname: " Kamil",
      lastname: " GRADEK",
      number: " 64",
      team: " CCC Team",
    },
    {
      firstname: " Víctor",
      lastname: " DE LA PARTE",
      number: " 63",
      team: " CCC Team",
    },
    {
      firstname: " Josef",
      lastname: " ČERNÝ",
      number: " 62",
      team: " CCC Team",
    },
    {
      firstname: " Ilnur",
      lastname: " ZAKARIN",
      number: " 61",
      team: " CCC Team",
    },
    {
      firstname: " Paweł",
      lastname: " POLJAŃSKI",
      number: " 58",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Rafał",
      lastname: " MAJKA",
      number: " 57",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Patrick",
      lastname: " KONRAD",
      number: " 56",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Patrick",
      lastname: " GAMPER",
      number: " 55",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Matteo",
      lastname: " FABBRO",
      number: " 54",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Maciej",
      lastname: " BODNAR",
      number: " 53",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Cesare",
      lastname: " BENEDETTI",
      number: " 52",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Peter",
      lastname: " SAGAN",
      number: " 51",
      team: " BORA - hansgrohe",
    },
    {
      firstname: " Filippo",
      lastname: " ZANA",
      number: " 48",
      team: " Bardiani-CSF-Faizanè",
    },
    {
      firstname: " Alessandro",
      lastname: " TONELLI",
      number: " 47",
      team: " Bardiani-CSF-Faizanè",
    },
    {
      firstname: " Francesco",
      lastname: " ROMANO",
      number: " 46",
      team: " Bardiani-CSF-Faizanè",
    },
    {
      firstname: " Fabio",
      lastname: " MAZZUCCO",
      number: " 45",
      team: " Bardiani-CSF-Faizanè",
    },
    {
      firstname: " Giovanni",
      lastname: " LONARDI",
      number: " 44",
      team: " Bardiani-CSF-Faizanè",
    },
    {
      firstname: " Filippo",
      lastname: " FIORELLI",
      number: " 43",
      team: " Bardiani-CSF-Faizanè",
    },
    {
      firstname: " Luca",
      lastname: " COVILI",
      number: " 42",
      team: " Bardiani-CSF-Faizanè",
    },
    {
      firstname: " Giovanni",
      lastname: " CARBONI",
      number: " 41",
      team: " Bardiani-CSF-Faizanè",
    },
    {
      firstname: " Jan",
      lastname: " TRATNIK",
      number: " 38",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Hermann",
      lastname: " PERNSTEINER",
      number: " 37",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Mark",
      lastname: " PADUN",
      number: " 36",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Domen",
      lastname: " NOVAK",
      number: " 35",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Eros",
      lastname: " CAPECCHI",
      number: " 34",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Pello",
      lastname: " BILBAO",
      number: " 33",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Enrico",
      lastname: " BATTAGLIN",
      number: " 32",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Yukiya",
      lastname: " ARASHIRO",
      number: " 31",
      team: " Bahrain - McLaren",
    },
    {
      firstname: " Aleksandr",
      lastname: " VLASOV",
      number: " 28",
      team: " Astana Pro Team",
    },
    {
      firstname: " Óscar",
      lastname: " RODRÍGUEZ",
      number: " 27",
      team: " Astana Pro Team",
    },
    {
      firstname: " Miguel Ángel",
      lastname: " LÓPEZ",
      number: " 26",
      team: " Astana Pro Team",
    },
    {
      firstname: " Jonas",
      lastname: " GREGAARD",
      number: " 25",
      team: " Astana Pro Team",
    },
    {
      firstname: " Fabio",
      lastname: " FELLINE",
      number: " 24",
      team: " Astana Pro Team",
    },
    {
      firstname: " Rodrigo",
      lastname: " CONTRERAS",
      number: " 23",
      team: " Astana Pro Team",
    },
    {
      firstname: " Manuele",
      lastname: " BOARO",
      number: " 22",
      team: " Astana Pro Team",
    },
    {
      firstname: " Jakob",
      lastname: " FUGLSANG",
      number: " 21",
      team: " Astana Pro Team",
    },
    {
      firstname: " Josip",
      lastname: " RUMAC",
      number: " 18",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      firstname: " Jhonatan",
      lastname: " RESTREPO",
      number: " 17",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      firstname: " Simone",
      lastname: " RAVANELLI",
      number: " 16",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      firstname: " Simon",
      lastname: " PELLAUD",
      number: " 15",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      firstname: " Luca",
      lastname: " CHIRICO",
      number: " 14",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      firstname: " Jefferson Alexander",
      lastname: " CEPEDA",
      number: " 13",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      firstname: " Alessandro",
      lastname: " BISOLTI",
      number: " 12",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      firstname: " Mattia",
      lastname: " BAIS",
      number: " 11",
      team: " Androni Giocattoli - Sidermec",
    },
    {
      firstname: " Larry",
      lastname: " WARBASSE",
      number: " 8",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Andrea",
      lastname: " VENDRAME",
      number: " 7",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Aurélien",
      lastname: " PARET-PEINTRE",
      number: " 6",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Jaakko",
      lastname: " HÄNNINEN",
      number: " 5",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Ben",
      lastname: " GASTAUER",
      number: " 4",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Geoffrey",
      lastname: " BOUCHARD",
      number: " 3",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " François",
      lastname: " BIDARD",
      number: " 2",
      team: " AG2R La Mondiale",
    },
    {
      firstname: " Tony",
      lastname: " GALLOPIN",
      number: " 1",
      team: " AG2R La Mondiale",
    },
  ]

  const race = "Giro"

  return (
    <Racepage title="Giro d'Italia 2020" startlist={startlist} race={race} />
  )
}

export default Giro2020
